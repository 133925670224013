import React from "react"
import styled from "styled-components"

interface IPillInterface {
    bg: string;
    color: string;
}

const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const Pills = styled.ul`
    display: block;
    list-style-type: none;
    margin: 1em 0 0 0;
`
const Pill = styled.li<IPillInterface>`
    width: fit-content;
    border: 2px solid #999;
    border-radius: 5%;
    padding: 5px 15px;
    margin: 0.25em 0;
    background: ${props => props.bg};
    color: ${props => props.color};

    &:hover {
        cursor: pointer;
    }
`

const CHAINS = [{
    name: "Ethereum",
    bg: "#3c3c3d",
    color: "#FFF",
    api: `https://api.etherscan.io/api?module=account&action=txlist&address={address}&startblock=0&endblock=99999999&page=1&offset=0&sort=asc&apikey={apikey}`,
    rpc: `https://mainnet.infura.io/v3/c047fc2c05444ccfbdf60ee05013f08a`,
    default_api_key: `KFS4CK4IZ3MN4HUHKYS638TUCK8F4N91MP`
}, {
    name: "BinanceSmartChain",
    bg: "#f3ba2f",
    color: "#222",
    api: `https://api.bscscan.com/api?module=account&action=txlist&address={address}&startblock=0&endblock=99999999&page=1&offset=0&sort=asc&apikey={apikey}`,
    rpc: `https://bsc-dataseed.binance.org/`,
    default_api_key: `DNBXDD5IQE66C8HRABBZ3JNWTWFAVP5YVF`
}, {
    name: "Polygon",
    bg: "#8247e5",
    color: "#FFF",
    api: `https://api.polygonscan.com/api?module=account&action=txlist&address={address}&startblock=0&endblock=99999999&page=1&offset=0&sort=asc&apikey={apikey}`,
    rpc: `https://polygon-rpc.com`,
    default_api_key: `14BXANX7YQ81GY1RJSXXN9JBIRCJDC4EFA`
}]


const ChooseChain = (props: any) => {

    const handleClick = (chain: any) => {
        props.callback({
            nextStep: 1,
            api: chain.api,
            rpc: chain.rpc,
            apiKey: chain.default_api_key
        })
    }

    return (<>
        Which chain are you looking at?
        <SubTitle>
            The tool currently supports the following EVM chains.
        </SubTitle>

        <Pills>
            {
                CHAINS.map((chain) => {
                    return(
                        <Pill color={chain.color} bg={chain.bg} onClick={() => handleClick(chain)}>{chain.name}</Pill>
                    )
                })
            }
        </Pills>
    </>)
}

export default ChooseChain